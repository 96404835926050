// colors
$gray97: #97aac3;
$grayF4: #f4f8fe;
$black4E: #4e4b64;
$textColor: #4f4b66;
$grayColor: #e9edf5;

// Rabbit Color
$primaryColor: #005098;
$buttonPrimaryBgHover: #003d74;

// Grid breakpoints
// It set following Charkra/theme.js config
$breakpoints-xs: 0;
$breakpoints-sm: 22.5em;
$breakpoints-md: 48em;
$breakpoints-lg: 60em;
$breakpoints-xl: 75em;
