@import '@styles/sass/variables';
@import '@styles/sass/mixins/rem';

.customTable {
  &__content {
    color: $textColor;

    figure {
      display: flex !important;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }

    a:link,
    a:visited {
      color: $primaryColor;
      text-decoration: underline;
    }

    a:hover,
    a:active {
      color: $buttonPrimaryBgHover;
    }

    td {
      border-color: $grayColor;
    }

    li {
      margin-left: rem(20);
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }
  }

  @media only screen and (max-width: 1109px) {
    &__content {
      display: block;
      overflow: hidden;
      width: 95%;
      margin-left: auto;
      margin-right: auto;

      figure {
        display: block;
      }
    }

    table {
      max-width: 95vw;
      width: 95vw;
      font-size: 2vw;

      span {
        font-size: 2vw !important;
      }

      h4 span {
        font-size: 2.6vw !important;
      }
      h5 span {
        font-size: 2.4vw !important;
      }
      h6 span {
        font-size: 2.2vw !important;
      }
    }
  }
}
